<template>
<div v-if="client != false">
  <nav class="navbar">
    <div class="navbar__logo"> <img :src="$api.ste.logo" alt="Logo" class="logo"> </div>
    <div class="navbar__agent">
      <div class="agent-info">
        <h3>{{ agent.nom }} {{ agent.prenom }}</h3>
        <div class="agent-contact"> 
          <a :href="`mailto:${agent.email}`" class="contact-link"> <i class="fas fa-envelope"></i> {{ agent.email }} </a> 
          <a :href="`tel:${agent.telephone}`" class="contact-link"> <i class="fas fa-phone"></i> {{ agent.telephone }} </a>           
        </div>
      </div>
    </div>
  </nav>
  <div class="app-container"> 
    <div v-if="currentStep === 'search'">
      <b-row class="mb-5">
        <b-col md="3" class="p-1">
          <div class="bg-white rounded px-5 py-3 position-relative" v-b-toggle.client-edit>                    
            <div class="d-flex align-items-center">
              <div class="client-circle">
                <i class="fa-2x" :class="iconClient"></i>
              </div>
              <div class="ml-3">
                <span class="h5">{{ titre }}</span><br/>
                <span>Date de naissance: <strong>{{ $api.moment(client.date_naissance).format("DD/MM/YYYY") }}</strong></span><br/>
                <span>Regime: <strong>{{ regime(client.regime_id) }}</strong></span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="3" class="p-1 d-none d-md-block" v-for="(a, i) in adherents" :key="i">
          <div class="bg-white rounded px-5 py-3">
            <div class="d-flex align-items-center">          
              <div class="client-circle">
                <i class="fa-2x" :class="a.icon"></i>
              </div>
              <div class="ml-3">
                <span class="h5"> {{ a.type }}: {{ age(a.date_naissance) }} ans</span><br/>
                <span>Date de naissance: <strong>{{ $api.moment(a.date_naissance).format("DD/MM/YYYY") }}</strong></span><br/>
                <span>Regime: <strong>{{ regime(a.regime_id) }}</strong></span>
              </div>
            </div>
          </div>
        </b-col>      
      </b-row>
      <div class="justify-content-between mb-3 d-none d-md-flex">
        <div>
          <b-button size="lg" variant="secondary" class="bg-white" @click="setSort()">{{ sort.value == 'DESC' ? 'Prix décroissant' : 'Prix croissant' }} <i class="fas" :class="`fa-${sort.value == 'DESC' ? 'sort-amount-down' : 'sort-amount-up'}`"></i></b-button>
          <b-button size="lg" variant="secondary" class="bg-white ml-2" v-b-toggle.filter-collapse>Modifier vos besoins ? <i class="fas fa-filter"></i></b-button>
        </div>
        <div>
          <b-button size="lg" variant="primary" v-b-toggle.client-edit>Modifier vos informations</b-button>
        </div>
      </div>        
      <div class="mb-3 d-block d-md-none">
        <b-button-group>
          <b-button size="sm" variant="secondary" class="bg-white" @click="setSort()">{{ sort.value == 'DESC' ? 'Prix décroissant' : 'Prix croissant' }} <i class="fas" :class="`fa-${sort.value == 'DESC' ? 'sort-amount-down' : 'sort-amount-up'}`"></i></b-button>
          <b-button size="sm" variant="secondary" class="bg-white" v-b-toggle.filter-collapse>Modifier vos besoins ? <i class="fas fa-filter"></i></b-button>
          <b-button size="sm" variant="primary" v-b-toggle.client-edit>Modifier vos informations</b-button>
        </b-button-group>
      </div>
      <b-collapse v-model="filterMenu" id="filter-collapse">
        <b-row class="text-center text-md-left">
          <b-col md="3" v-for="(k, i) in Object.keys(filters)" :key="i" class="mb-3">          
            <p class="h5 d-flex align-items-center text-center text-md-left justify-content-center justify-content-md-start">
              <i class="fa-2x mr-4" :class="filters[k].icon"></i> {{ k }}
            </p>
            <b-button-group class="bg-white mt-1">
              <b-button :variant="`${filters[k].value === 1 ? '' : 'outline-'}primary`" @click="filters[k].value = 1">Mini</b-button>
              <b-button :variant="`${filters[k].value === 2 ? '' : 'outline-'}primary`" @click="filters[k].value = 2">Moyen</b-button>
              <b-button :variant="`${filters[k].value === 3 ? '' : 'outline-'}primary`" @click="filters[k].value = 3">Fort</b-button>
              <b-button :variant="`${filters[k].value === 4 ? '' : 'outline-'}primary`" @click="filters[k].value = 4">Maxi</b-button>
            </b-button-group>          
          </b-col>
        </b-row>
      </b-collapse>
      <div class="mt-3">
        <p class="h3">En seulement une minute, découvrez votre projet de mutuelle. <span class="text-danger">({{datatable.totalRow }} offres)</span></p>      
        <p class="h5">Les montants des cotisations sont basés sur une date d'effet au <strong>{{ $api.moment(client.date_effet).format("DD/MM/YYYY") }}</strong>.</p>      
      </div>
      <div class="mt-5" v-if="price_load === false">
        <div class="card shadow-sm rounded-lg px-5 py-5 mb-3" v-for="(o, i) in datatable.data" :key="i">
          <div class="d-flex justify-content-between align-items-center">
            <span class="badge" :class="`badge-${o.responsable ? 'primary' : 'danger'}`">Formule {{o.responsable ? '' : 'non '}}résponsable </span>
          </div>
          <b-row class="align-items-center">
            <b-col md="3" class="mb-3">
              <div class="text-center">
                <img :src="$api.link(o.logo)" alt="Logo" class="" style="max-height: 70px;">              
              </div>            
            </b-col>
            <b-col md="3" class="mb-3">
              <div class="text-center">
                <span class="h4">{{ o.formule }}</span><br/>
                <hr>
                <span class="h5 text-primary text-uppercase">{{ o.produit }}</span><br/>
                <hr>
                <span style="font-weight: 700; font-size: 35px;">{{ $api.price(o.tarif) }}</span><span>/mois</span><br/><br/>
                <span class="text-muted h6">Soit {{ $api.price(o.tarif*12) }} /an</span>
              </div>
              <div class="text-center mt-3">
                <b-button variant="outline-secondary" v-b-toggle="`docs-${o.id}`" class="btn-sm">Voir les documents</b-button>          
                <b-collapse :id="`docs-${o.id}`">
                  <div class="mt-3">
                    <b-button v-for="(d, id) in o.docs.filter(y => y.value != null)" :key="id" :href="$api.link(d.value)" target="_blank" class="mb-1" block variant="outline-primary">{{ d.key }}</b-button>
                  </div>
                </b-collapse>
              </div>
            </b-col>
            <b-col md="3" class="mb-3">
              <div class="col text-center mb-3" v-for="(k, i) in Object.keys(filters)" :key="i">
                <small class="text-muted h6">{{ k }}</small>
                <div class="d-flex justify-content-center mt-1">
                  <div class="rectangle mr-1" :class="`bg-${o.site[k] > 0 ? '' : 'light-'}primary`"></div>
                  <div class="rectangle mr-1" :class="`bg-${o.site[k] > 1 ? '' : 'light-'}primary`"></div>
                  <div class="rectangle mr-1" :class="`bg-${o.site[k] > 2 ? '' : 'light-'}primary`"></div>
                  <div class="rectangle" :class="`bg-${o.site[k] > 3 ? '' : 'light-'}primary`"></div>              
                </div>
              </div>           
            </b-col>
            <b-col md="3">
              <b-button variant="outline-danger" class="m-1 mb-3" block :href="`tel:${agent.telephone}`"><i class="fas fa-phone"></i> Appeler {{ agent.prenom }} {{ agent.telephone }}</b-button>
              <b-button :variant="checkQuote(o) ? 'primary' : 'danger'" @click="addQuote(o)" class="m-1" block>{{ checkQuote(o) ? 'Retirez' : 'Choisir' }} ce devis gratuit</b-button>
            </b-col>
          </b-row>                       
        </div>
      </div>  
      <div v-else class="d-flex justify-content-center mb-3 p-5">
        <b-spinner style="width:5rem;height:5rem;" variant="danger" type="grow"></b-spinner>
      </div>
    </div>
    <div v-if="currentStep === 'devis'">
      <div>
        <div class="card shadow-sm rounded-lg px-5 py-5 mb-3">
          <h2 class="text-center mb-4">Récapitulatif de votre demande</h2>      
          <!-- Selected offers recap -->
           <div class="mb-4">
            <h4 class="mb-3">Offres sélectionnées</h4>
            <div class="card shadow-sm rounded-lg px-2 py-2 mb-3" v-for="(o, i) in selected" :key="i">              
              <b-row class="align-items-center">
                <b-col md="3" class="mb-3">
                  <div class="text-center">
                    <img :src="$api.link(o.logo)" alt="Logo" class="" style="max-height: 70px;">              
                  </div>            
                </b-col>
                <b-col md="3" class="mb-3">
                  <div class="text-center">
                    <span class="h4">{{ o.formule }}</span><br/>
                    <hr>
                    <span class="h5 text-primary text-uppercase">{{ o.produit }}</span><br/>
                    <hr>
                    <span style="font-weight: 700; font-size: 35px;">{{ $api.price(o.tarif) }}</span><span>/mois</span><br/><br/>
                    <span class="text-muted h6">Soit {{ $api.price(o.tarif*12) }} /an</span>
                  </div>                  
                </b-col>
                <b-col md="3" class="mb-3">
                  <div class="col text-center mb-3" v-for="(k, i) in Object.keys(filters)" :key="i">
                    <small class="text-muted h6">{{ k }}</small>
                    <div class="d-flex justify-content-center mt-1">
                      <div class="rectangle mr-1" :class="`bg-${o.site[k] > 0 ? '' : 'light-'}primary`"></div>
                      <div class="rectangle mr-1" :class="`bg-${o.site[k] > 1 ? '' : 'light-'}primary`"></div>
                      <div class="rectangle mr-1" :class="`bg-${o.site[k] > 2 ? '' : 'light-'}primary`"></div>
                      <div class="rectangle" :class="`bg-${o.site[k] > 3 ? '' : 'light-'}primary`"></div>              
                    </div>
                  </div>           
                </b-col>
                <b-col md="3">                  
                  <b-button v-if="selected.length > 1" variant="danger" @click="addQuote(o)" class="m-1" block>{{ checkQuote(o) ? 'Retirez' : 'Choisir' }} ce devis gratuit</b-button>
                </b-col>
              </b-row>                       
            </div>            
          </div>      
          <b-form @submit.prevent="submitQuoteRequest" class="quote-request-form">
            <b-form-group label="Numéro de téléphone" label-for="phone" :invalid-feedback="phoneError" :state="phoneState">
              <b-form-input id="phone" v-model="client.telephone_1" type="tel" :state="phoneState" placeholder="Votre numéro de téléphone" required="required"></b-form-input>
            </b-form-group>
            <b-form-group label="Email">
              <b-form-input id="phone" v-model="client.email" type="email" placeholder="Votre numéro adresse mail" ></b-form-input>
            </b-form-group>
            <b-form-group label="Quel budget souhaitez-vous consacrer à votre mutuelle ?" label-for="budget" :invalid-feedback="budgetError" :state="budgetState">
              <b-input-group size="lg" prepend="€">
                <b-form-input id="budget" v-model="quoteForm.budget" type="number" min="0" max="999" :state="budgetState" placeholder="Votre budget mensuel"></b-form-input>
              </b-input-group>              
            </b-form-group>
            <b-form-group label="Avez-vous déjà une mutuelle ?">
              <b-button-group>
                <b-button  :variant="quoteForm.hasInsurance ? 'primary' : 'outline-primary'" @click="quoteForm.hasInsurance = true">
                  Oui
                </b-button>
                <b-button  :variant="!quoteForm.hasInsurance ? 'primary' : 'outline-primary'" @click="quoteForm.hasInsurance = false">
                  Non
                </b-button>
              </b-button-group>
            </b-form-group>            
            <b-form-checkbox size="lg" v-model="quoteForm.optin" :value="true" :unchecked-value="false" required="required">J’accepte les conditions générales d’utilisation et accepte d’être rappelé par un conseiller.</b-form-checkbox>            
            <div class="d-flex justify-content-between mt-4">
              <b-button  variant="outline-secondary"  @click="currentStep = 'search'" >
                Retour
              </b-button>
              <b-button  type="submit"  variant="primary" :disabled="!phoneState">
                Envoyer la demande
              </b-button>
            </div>
          </b-form>
        </div>
      </div>      
    </div>
  </div>
  <b-sidebar v-if="currentStep === 'search'" id="client-edit" ref="client-edit" width="500px" backdrop no-header right shadow>    
    <div class="px-3 py-2">
      <i class="fas fa-2x fa-times text-primary" style="cursor: pointer;" @click="$refs['client-edit'].hide()"></i>
      <b-card class="card-search">
        <h4 class="mb-3">Informations Personnelles</h4>
        <b-form @submit.prevent="submitForm">
          <b-row>
            <b-col md="6">
              <b-form-group label="Nom" label-for="nom">
                <b-form-input id="nom" v-model="client.nom" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Prénom" label-for="prenom">
                <b-form-input id="prenom" v-model="client.prenom" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Email" label-for="email">
                <b-form-input id="email" v-model="client.email" type="email" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Téléphone" label-for="telephone">
                <b-form-input id="telephone" v-model="client.telephone" maxlength="10" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Code Postal" label-for="code_postal">
                <b-form-input id="code_postal" v-model="client.cp" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Date de Naissance" label-for="date_naissance">
                <b-form-input id="date_naissance" v-model="client.date_naissance" type="date" required :max="limitAgeAdulte"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Régime" label-for="regime">
                <select v-model="client.regime_id" class="form-control">
                  <option :value="null">Sélectionnez</option>
                  <option v-for="(r, i) in params.Regime" :key="i" :value="r.id">{{ r.titre }}</option>
                </select>                
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Date d'effet" label-for="date_effet">
                <b-form-input id="date_effet" v-model="client.date_effet" :min="$api.moment().format('YYYY-MM-DD')" type="date" required></b-form-input>
              </b-form-group>
            </b-col>            
          </b-row>          
          <hr/>
          <!-- Conjoint -->
          <b-form-group>
            <b-form-checkbox v-model="client.conjoint.regime_id" :value="1" :unchecked-value="null" size="lg">Ajoutez un conjoint ?</b-form-checkbox>
          </b-form-group>
          <div v-if="client.conjoint.regime_id != null">
            <h5>Informations Conjoint</h5>
            <b-row>
              <b-col md="6">
                <b-form-group label="Date de Naissance" label-for="date_naissance_conjoint">
                  <b-form-input id="date_naissance_conjoint" v-model="client.conjoint.date_naissance" :max="limitAgeAdulte" type="date"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Régime" label-for="regime">
                  <select v-model="client.conjoint.regime_id" class="form-control">
                    <option :value="null">Sélectionnez</option>
                    <option v-for="(r, i) in params.Regime" :key="i" :value="r.id">{{ r.titre }}</option>
                  </select>                
                </b-form-group>
              </b-col>
            </b-row>                        
          </div>
          <hr/>          
          <h5>Enfants</h5>
          <span >Ajoutez un enfants ? <b-button size="xs" variant="success" class="btn-icon" @click="client.enfants.push({date_naissance: null, regime_id: null})"><i class="fas fa-plus"></i></b-button></span>          

          <div v-for="(enfant, index) in client.enfants" :key="index" class="mt-3">
            <b-row>              
              <b-col md="6">                
                <b-form-group label="Date de Naissance" label-for="date_naissance_enfant">
                  <b-input-group>
                    <b-form-input v-model="enfant.date_naissance" type="date" :min="limitAgeEnfant" :max="$api.moment().format('YYYY-MM-DD')"></b-form-input>
                    <template #append>
                      <b-button variant="light-danger" class="btn-icon" @click="client.enfants.splice(index,1)"><i class="fas fa-trash"></i></b-button>
                    </template>
                  </b-input-group>                  
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Régime" label-for="regime_enfant">
                  <select v-model="enfant.regime_id" class="form-control">
                    <option :value="null">Sélectionnez</option>
                    <option v-for="(r, i) in params.Regime" :key="i" :value="r.id">{{ r.titre }}</option>
                  </select>                
                </b-form-group>
              </b-col>              
            </b-row>            
          </div>
          <hr/>
          <div class="text-center">
            <b-button type="submit" variant="primary" block>Mettre à jour</b-button>
          </div>
        </b-form>
      </b-card>
    </div>
  </b-sidebar>
  <div class="fixed-bottom  text-white text-center py-15" v-if="selected.length > 0 && currentStep === 'search'">
    <b-button size="lg" variant="primary" @click="currentStep = 'devis'"><strong class="h4">Recevoir le devis</strong> ({{ selected.length }} offres)</b-button>
  </div>
  <a href="https://wa.me/1234567890" target="_blank" class="whatsapp-button">
      <i class="fab fa-whatsapp"></i>
  </a>
</div>
</template>
<script>
export default {
  data() {
    return {      
      price_load: true,    
      currentStep: "search",  
      params: {},
      client: false,
      filterMenu: false,
      agent: {
        nom: 'Dupont',
        prenom: 'Jean',
        email: 'jean.dupont@company.fr',
        telephone: '06 12 34 56 78',
        whatsapp: '33612345678'
      },            
      filters:{
        'Soins courants': {
          icon: "fas fa-briefcase-medical",
          value: null
        },
        'Hospitalisation': {
          icon: "far fa-hospital",
          value: null
        },
        'Dentaire': {
          icon: "fas fa-tooth",
          value: null
        },
        'Optique': {
          icon: "fas fa-glasses",
          value: null
        },        
      }, 
      sort:{key:"tarif", value:"ASC"},   
      datatable: {
        "currentPage": 1,
        "limitPage": 10000,
        "totalRow": 0,
        "data": []
      }, 
      selected:[],
      quoteForm: {
        phone: '',
        budget: null,
        hasInsurance: null,
        optin: false
      },       
    }
  },
  computed: {
    phoneState() {
      return this.client.telephone_1.length === 0 || 
        (this.client.telephone_1.length === 10 && /^[0-9]+$/.test(this.client.telephone_1));
    },
    phoneError() {
      if (this.client.telephone_1.length === 0) return '';
      if (this.client.telephone_1.length !== 10) return 'Le numéro doit contenir 10 chiffres';
      if (!/^[0-9]+$/.test(this.client.telephone_1)) return 'Le numéro ne doit contenir que des chiffres';
      return '';
    }, 
    budgetError(){
      if(this.quoteForm.budget === null) return '';
      if(this.quoteForm.budget > 999) return 'Le montant maximum doit être 999€';
      return '';
    },
    budgetState(){
      if(this.quoteForm.budget === null) return null;
      return this.quoteForm.budget < 999;
    },
    iconClient(){
      if(this.client.enfants.length > 0){
        return 'fas fa-users'
      }else if(this.client.conjoint.date_naissance){
        return 'fas fa-user-friends'
      }else{
        return 'fas fa-user';
      }
    },
    adherents(){
      let res = [];
      if(this.client.conjoint.regime_id != null){
        res.push({type:"Conjoint", index:0, icon:"fas fa-user", date_naissance: this.client.conjoint.date_naissance, regime_id: this.client.conjoint.regime_id});
      }
      this.client.enfants.forEach((e, i) => {
        res.push({type:"Enfant", index:i, icon:"fas fa-user", date_naissance: e.date_naissance, regime_id: e.regime_id});
      })
      return res;
    },
    titre(){
      let titre = `${ this.client.nom } ${ this.client.prenom }`;
      let sub = "";
      if(this.client.conjoint.regime_id != null){
        sub += "Conjoint"
      }
      if(this.client.enfants.length > 0){
        sub += (sub.length > 0 ? " + " : "") + `${this.client.enfants.length} enfant${this.client.enfants.length > 1 ? 's' : ''}`
      }
      if(sub.length > 0){
        titre += ` (${sub})`;
      }
      return titre;
    },
    limitAgeEnfant(){
      const maxDate = this.$api.moment().subtract(26, 'years')
      return maxDate.format("YYYY-MM-DD");
    },
    limitAgeAdulte(){
      const maxDate = this.$api.moment().subtract(18, 'years')
      return maxDate.format("YYYY-MM-DD");
    }
  },
  watch:{
    filters:{
      deep: true,
      handler(){
        this.search()
      }
    }
  },
  methods: {    
    search(){
      this.price_load = true;
      let filters = {};
      Object.keys(this.filters).forEach(k => {
        filters["min_site."+k] = this.filters[k].value;
      })
      let params = {
        filters: filters,
        sort: this.sort,
        page: this.datatable.currentPage,
        limit: this.datatable.limitPage
      }
      this.$api.ajax(`/parcour/search?api=${this.$route.params.api}`, params, res => {
        this.datatable = res
        this.price_load = false;
      });
    },
    age(date){      
      date = this.$api.moment(date);
      return this.$api.moment().diff(date, 'years');
    },
    checkQuote(quote){
      let check = this.selected.find(s => s.id === quote.id);
      return check ? true : false
    },
    addQuote(quote){
      let check = this.selected.find(s => s.id === quote.id);
      if(check){
        this.selected = this.selected.filter(n => n.id != quote.id);
        return false;
      }else{
        this.selected.push(quote)
      }      
    },
    regime(id){
      let regime = this.params.Regime.find(r => r.id === id);            
      if(!regime){
        return '';
      }
      return regime.titre;
    },
    setSort(){
      this.sort.value = this.sort.value == 'DESC' ? 'ASC' : 'DESC';
      this.search()
    },
    submitForm(e){
      console.log(e);
      this.$refs['client-edit'].hide()
    },
    submitQuoteRequest(a,){
      console.log({a});
    }
  },  
  beforeMount(){
    if(window.matchMedia("(max-width: 767px)").matches){
      this.filterMenu = false;
    }
  },
  mounted(){
    this.$api.ajax(`/parcour/me?api=${this.$route.params.api}`, null, res => {
      if(res.status){
        this.params = res.data.params;
        this.client = res.data.client;
        this.agent = res.data.user;
        this.search();
      }
    })
  }
}
</script>
  
<style scoped>
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  
  text-decoration: none;
  z-index: 1000;
}
.whatsapp-button i {
  color: white !important;
  font-size: 28px;
}
.whatsapp-button:hover {
  background-color: #1ebe5d;
  color: white;
}

.rectangle {
  width  : 25px;
  height : 13px;
  border-radius: 15px;
}
.client-circle {
  width: 50px;
  height: 50px;
  background-color: #f4f6f7; /* Couleur success de Bootstrap */
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;  
}
.edit-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #6c757d; /* Couleur gris Bootstrap */
  cursor: pointer;
  transition: color 0.3s ease;
}

.edit-icon:hover {
  color: #2096f3
}
.rectangle.bg-light-primary{
  background-color: #c0e9fd !important;
}
.app-container {
  min-height: 100vh;
  background: #ecf0f4;
  padding: 2rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: white;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.navbar__logo {
  flex-shrink: 0;
}

.logo {
  height: 40px;
  width: auto;
}

.navbar__agent {
  text-align: right;
}

.agent-info h3 {
  margin: 0 0 0.5rem 0;
  color: #333;
  font-size: 1.1rem;
}

.agent-contact {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.contact-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: #699ECF;
}

.contact-link i {
  font-size: 1.1rem;
}

.whatsapp {
  color: #25D366;
}

.whatsapp:hover {
  color: #128C7E;
}  
</style>